export const logos = [
  'https://www.mlytics.com/wp-content/uploads/2018/10/akamai-2.png',
  'https://www.mlytics.com/wp-content/uploads/2018/10/CDNetworks-1.png',
  'https://www.mlytics.com/wp-content/uploads/2018/10/aws-2.png',
  'https://www.mlytics.com/wp-content/uploads/2021/06/Tencent_logo_2.png',
  'https://www.mlytics.com/wp-content/uploads/2019/06/alibaba-cloud-cdn.png',
  'https://www.mlytics.com/wp-content/uploads/2020/07/chinacachelogo-grey-small.png',
  'https://www.mlytics.com/wp-content/uploads/2022/09/logo-vncdn-grey-small.png'
]

export const Features = [
  {
    image: 'https://www.mlytics.com/wp-content/uploads/2021/08/icon_Easy-deployment.png',
    title: 'multiCDN.section4.card1.title',
    content: 'multiCDN.section4.card1.content'
  },
  {
    image: 'https://www.mlytics.com/wp-content/uploads/2021/11/icon_Easy-management.png',
    title: 'multiCDN.section4.card2.title',
    content: 'multiCDN.section4.card2.content'
  },
  {
    image: 'https://www.mlytics.com/wp-content/uploads/2021/11/icon_All-in-one.png',
    title: 'multiCDN.section4.card3.title',
    content: 'multiCDN.section4.card3.content'
  }
]
